import { MenuItem, Select } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CollapsibleTable from "components/Table";
import { useEffect, useState } from "react";
import services from "services/request";
import "./App.css";
import dayjs from "dayjs";

const GRANULARITIES_DATA = [
  {
    id: 0,
    time: 15 * 60 * 1000,
    resolution: 1 * 60 * 1000,
    name: "1 min over 15 mins",
  },
  {
    id: 1,
    time: 1 * 60 * 60 * 1000,
    resolution: 5 * 60 * 1000,
    name: "5 mins over 1 hour",
  },
  {
    id: 2,
    time: 4 * 60 * 60 * 1000,
    resolution: 15 * 60 * 1000,
    name: "15 mins over 4 hours",
  },
  {
    id: 3,
    time: 12 * 60 * 60 * 1000,
    resolution: 1 * 60 * 60 * 1000,
    name: "1 hour over 12 hours",
  },
];

function App() {
  const [listFeeData, setListFeeData] = useState<any[]>([]);
  const [listDataToken, setListDataToken] = useState<any[]>([]);
  const [currentToken, setCurrentToken] = useState<any>();
  const [toTimestamp, setToTimestamp] = useState<any>(dayjs(1665089380000));

  const [granularitiesId, setGranularitiesId] = useState<any>(
    GRANULARITIES_DATA[0].id
  );

  const fetchListDataToken = async () => {
    const res = await services.getListToken();
    setListDataToken(res.data);

    if (res.data.length > 0) {
      setCurrentToken(res.data[0].address);
    }
  };

  const handleChangeToken = (event: any) => {
    setCurrentToken(event.target.value);
  };

  const handleChangeGranularities = (event: any) => {
    setGranularitiesId(event.target.value);
  };

  const fetchSwapHistory = async (currentToken: any, granularities: any) => {
    if (!toTimestamp || !currentToken || !granularities) return;
    const fromTimestamp = toTimestamp.valueOf() - granularities.time;

    const res = await services.getFee({
      "to-token": currentToken,
      "to-timestamp": toTimestamp.valueOf(),
      "from-timestamp": fromTimestamp,
      resolution: granularities.resolution,
    });
    setListFeeData(res.data);
  };

  useEffect(() => {
    fetchListDataToken();
  }, []);

  useEffect(() => {
    fetchSwapHistory(currentToken, GRANULARITIES_DATA[granularitiesId]);
  }, [currentToken, granularitiesId, toTimestamp]);

  return (
    <div className="App">
      <div className="container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
        <h1>HIEU VU TEST PIPELINE TEST VKL TEST TEST TEST NE</h1>
          <Select
            onChange={handleChangeToken}
            value={currentToken || ""}
            label="Token"
            style={{ margin: 20 }}
          >
            {listDataToken.map((token) => (
              <MenuItem value={token.address}>{token.name}</MenuItem>
            ))}
          </Select>

          <Select
            onChange={handleChangeGranularities}
            value={granularitiesId}
            label="Granularities"
            style={{ margin: 20 }}
          >
            {GRANULARITIES_DATA.map((g) => (
              <MenuItem value={g.id}>{g.name}</MenuItem>
            ))}
          </Select>
          <div style={{ margin: 20 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Controlled picker"
                views={["year", "month", "day", "hours", "minutes", "seconds"]}
                value={toTimestamp}
                onAccept={(newValue) => {
                  setToTimestamp(newValue);
                }}
                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
              />
            </LocalizationProvider>
          </div>
        </div>

        {listDataToken && (
          <CollapsibleTable
            listFeeData={listFeeData}
            currentToken={currentToken}
            currentTokenDecimal={
              listDataToken.find((token) => token.address === currentToken)
                ?.decimals || 0
            }
            granularities={GRANULARITIES_DATA[granularitiesId]}
            toTimestamp={toTimestamp}
          />
        )}
      </div>
    </div>
  );
}

export default App;
