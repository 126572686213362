import { ServiceBase } from 'services/http/service-base';

class Services extends ServiceBase {
  getListToken = async () => {
    const res = await this.get('/swap-history/token');
    return res.data;
  };
  getScan = async (params: any) => {
    const res = await this.getParams('/swap-history/scan', { ...params });
    return res.data;
  };
  getFee = async (params: any) => {
    const res = await this.getParams('/swap-history/fee', { ...params });
    return res.data;
  };
}

const services = new Services();

export default services;
