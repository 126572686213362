import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import services from "services/request";

interface CollapsibleTableProps {
  listFeeData: any;
  currentToken: any;
  currentTokenDecimal: any;
  granularities: any;
  toTimestamp: any;
}

interface CollapsibleRowProps {
  fee: any;
  currentToken: any;
  currentTokenDecimal: any;
  granularities: any;
  toTimestamp: any;
  index: any;
}

function Row(props: CollapsibleRowProps) {
  const [open, setOpen] = useState(false);
  const [listDataSwap, setListDataSwap] = useState<any>([1, 1, 1]);
  const fetchListDataSwap = async () => {
    const params = {
      "to-token": props.currentToken,
      "from-timestamp": props.toTimestamp
        .subtract(props.granularities.resolution * (props.index + 1), "ms")
        .valueOf(),
      "to-timestamp": props.toTimestamp
        .subtract(props.granularities.resolution * props.index, "ms")
        .valueOf(),
    };
    const res = await services.getScan(params);

    setListDataSwap(res.data);
  };

  useEffect(() => {
    if (open) {
      fetchListDataSwap();
    }
  }, [open]);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{props.index}</TableCell>
        <TableCell align="right">
          {props.toTimestamp
            .subtract(props.granularities.resolution * (props.index + 1), "ms")
            .format("YYYY-MM-DD hh:mm:ss")}
        </TableCell>
        <TableCell align="right">
          {props.toTimestamp
            .subtract(props.granularities.resolution * props.index, "ms")
            .format("YYYY-MM-DD hh:mm:ss")}
        </TableCell>
        <TableCell align="right">{props.fee}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>TxHash</TableCell>
                    <TableCell>Sender</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>From Token</TableCell>
                    <TableCell>To Token</TableCell>
                    <TableCell>From Amount</TableCell>
                    <TableCell>To Amount</TableCell>
                    <TableCell>Timestamp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listDataSwap.map((data: any, index: any) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography
                          width={100}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                        >
                          {data.txHash}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          width={100}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                        >
                          {data.sender}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          width={100}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                        >
                          {data.to}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          width={100}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                        >
                          {data.fromToken}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          width={100}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                        >
                          {data.toToken}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          width={100}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                        >
                          {new BigNumber(data.fromAmount)
                            .dividedBy(
                              new BigNumber(10).exponentiatedBy(
                                props.currentTokenDecimal
                              )
                            )
                            .toFixed(6)
                            .toString()}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          width={100}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                        >
                          {new BigNumber(data.toAmount)
                            .dividedBy(
                              new BigNumber(10).exponentiatedBy(
                                props.currentTokenDecimal
                              )
                            )
                            .toFixed(6)
                            .toString()}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          width={100}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                        >
                          {dayjs(data.timestamp).format("YYYY-MM-DD hh:mm:ss")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const CollapsibleTable: React.FC<CollapsibleTableProps> = ({
  listFeeData,
  currentToken,
  granularities,
  toTimestamp,
  currentTokenDecimal,
}) => {
  return (
    <div>
      <TableContainer component={Paper} style={{ minWidth: 800, margin: 50 }}>
        <Table aria-label="collapsible table" style={{ minWidth: 800 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">ID</TableCell>
              <TableCell align="right">From timestamp</TableCell>
              <TableCell align="right">To timestamp</TableCell>
              <TableCell align="right">Total swap fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listFeeData.map((fee: any, index: any) => (
              <Row
                key={index}
                index={index}
                fee={fee}
                currentToken={currentToken}
                granularities={granularities}
                toTimestamp={toTimestamp}
                currentTokenDecimal={currentTokenDecimal}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CollapsibleTable;
